@use "mixins" as *
.verificationModal :global(.modal)
  height: auto
  max-height: 100%
  width: 100%
  border-radius: 8px 8px 0 0
  --field_bg: var(--registerCpf_fieldBg)
  :global(.loader)
    position: static
    min-height: 418px

.header
  @include modalHeader

.subTitle
  font-size: 18px
  @include bold
  color: #fff
  @include flex
  margin: 10px 0 12px
  flex-direction: column

  > span
    margin-top: 10px
    font-size: 14px
    font-weight: normal

.verification
  padding: 0 20px 40px

  :global(.loader)
    background: transparent

  > form
    overflow: hidden
    padding: 6px 0

.row
  display: flex
  align-items: flex-start
  margin-bottom: 12px

  > div
    flex: 1

    label
      display: block
      text-transform: capitalize
      font-size: 14px
      margin-bottom: 6px

    &:not(:nth-child(1))
      margin-left: 6px

.buttonContainer
  display: flex
  button
    font-size: 14px
    &:nth-child(2)
      flex: 1
      margin-left: 10px

    &:nth-child(1)
      width: 110px

.laterMessage
  min-height: 200px
  padding: 10px
  @include flex
  flex-direction: column
  justify-content: center

  > p
    font-size: 16px
    line-height: 22px
    width: 80%
    max-width: 260px
    text-align: center
    margin-bottom: 30px
    color: #fff

  button
    width: 80%
    max-width: 200px
    height: 40px
    font-size: 14px
