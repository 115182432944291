@use "mixins" as *
.container
  border: 2px solid rgba(var(--primaryColor),.3)
  height: 300px
  margin-bottom: 6px
  position: relative
  border-radius: 6px
  height: 100%

.loader
  position: absolute
  top: calc(50% - 20px)
  left: calc(50% - 20px)
  width: 40px
  height: 40px
  border-radius: 50%
  opacity: .8
  perspective: 800px

  > span
    position: absolute
    box-sizing: border-box
    width: 100%
    height: 100%
    border-radius: 50%

    &.one
      left: 0%
      top: 0%
      animation: rotate-one 1s linear infinite
      border-bottom: 2px solid rgb(var(--primaryColor))

    &.two
      right: 0%
      top: 0%
      animation: rotate-two 1s linear infinite
      border-right: 2px solid rgb(var(--primaryColor))

    &.three
      right: 0%
      bottom: 0%
      animation: rotate-three 1s linear infinite
      border-top: 2px solid rgb(var(--primaryColor))

  @keyframes rotate-one
    0%
      transform: rotateX(40deg) rotateY(-45deg) rotateZ(0deg)

    100%
      transform: rotateX(40deg) rotateY(-45deg) rotateZ(360deg)

  @keyframes rotate-two
    0%
      transform: rotateX(55deg) rotateY(15deg) rotateZ(0deg)

    100%
      transform: rotateX(55deg) rotateY(15deg) rotateZ(360deg)

  @keyframes rotate-three
    0%
      transform: rotateX(40deg) rotateY(60deg) rotateZ(0deg)

    100%
      transform: rotateX(40deg) rotateY(60deg) rotateZ(360deg)
